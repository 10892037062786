/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import styles from './NewProModal.module.scss';
import { checkEmail } from '../../../../../common/data/actions';
import StripeForm from '../../../../pro/StripeForm/StripeForm';
import CloseIcon from './closeIcon.svg';
import CheckMark from '../../../../../public/icons/svg/blackCheckmark.svg';
import LoadingSpinner from '../../../UI/LoadingSpinner/LoadingSpinner';

const NewProModal = ({
  closeModal, isLandingPage, redirect, analytics,
}) => {
  const stripePromise = loadStripe(`${process.env.STRIPE_PK}`);
  const token = useSelector((state) => state.auth.token);
  const router = useRouter();
  // const [step, setStep] = useState(0);
  // const [membership, setMembership] = useState();
  const [loading, setLoading] = useState(false);
  // const [email, setEmail] = useState();
  const propEmail = router.query.email;
  const lightMode = useSelector((state) => state.main.lightMode);

  // useEffect(() => {
  //   console.log(step);
  // if (step === 2) {
  //   router.push({
  //     query: { email },
  //     pathname: '/marketplace?filter=my-jobs',
  //   });
  // }
  // }, [step]);

  const paymentSuccessful = () => {
    setLoading(false);
    if (redirect) {
      router.push(redirect);
    }
    if (analytics) {
      window.analytics.track(analytics);
    }
  };

  const topSection = () => (
    <>
      <div className={styles.logo}>
        <Image
          src={lightMode ? '/logo-pro.png' : '/logo-pro_darkII.png'}
          alt="Entre"
          width={164}
          height={30}
        />
      </div>
      <h2 className={styles.card__proTitle}>Become an Entre PRO member</h2>
      <div className={styles.card__header}>
        <h2 className={styles.card__title}>Annual Membership</h2>
        <div className={styles.pricing}>
          <span className={styles.pricing__amount}>$199 /</span>
          <span className={styles.pricing__duration}>Year</span>
        </div>
      </div>
      <div className={styles.card__text}>
        <div className={styles.card__row}>
          <div className={styles.card__checkmarks}>
            <CheckMark />
          </div>
          <p>PRO Badge on Profile</p>
        </div>
        <div className={styles.card__row}>
          <div className={styles.card__checkmarks}>
            <CheckMark />
          </div>
          <p>Record meetings and Events</p>
        </div>
        <div className={styles.card__row}>
          <div className={styles.card__checkmarks}>
            <CheckMark />
          </div>
          <p>Unlimited Job Postings</p>
        </div>
        <div className={styles.card__row}>
          <div className={styles.card__checkmarks}>
            <CheckMark />
          </div>
          <p>All Deals & Discounts</p>
        </div>
      </div>
    </>
  );

  const footerSection = () => (
    <div className={styles.card__footer}>
      <img src={lightMode ? '/images/pro/stripeSmallLight.png' : '/images/pro/stripeSmall.png'} alt="stripe" />
      <p>
        By signing you accept The
        {' '}
        <Link href="/terms">
          <a> Terms of Services</a>
        </Link>
        {' and '}
        <Link href="/terms">
          <a>Privacy Policy</a>
        </Link>
      </p>
    </div>
  );

  return (
    <div
      className={clsx(
        styles.ProModal,
        isLandingPage && styles.bgGrey,
      )}
    >
      {loading ? (
        <div className={styles.loader}>
          <LoadingSpinner size="big" />
          <p>Please wait while we upgrade your account...</p>
        </div>
      ) : (
        <>
          {!isLandingPage ? (
            <>
              <div className={styles.header}>
                <button
                  onClick={closeModal}
                  className={styles.goBack}
                  type="button"
                >
                  <CloseIcon />
                </button>
              </div>
            </>
          ) : null}
          <div className={styles.cards}>
            <div className={styles.card}>
              {topSection()}
              <Elements stripe={stripePromise}>
                <StripeForm
                  loading={loading}
                  setLoading={setLoading}
                  propEmail={propEmail}
                  cancelAction={() => {
                    closeModal();
                  }}
                  onClose={async (purchaseEmail) => {
                    closeModal();
                    if (!token && purchaseEmail) {
                      const exists = await checkEmail(purchaseEmail);
                      console.error(exists);
                    }
                    paymentSuccessful();
                  }}
                />
              </Elements>
              {!isLandingPage ? (
                <>
                  {footerSection()}
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
      {/* <Modal
        isOpen={step === 3}
        maximumWidth="52rem"
        onClose={step === 3 ? () => {} : () => setStep(0)}
        topOffSet="0%"
      >
        <div className={styles.ProModal}>
          <NewAuth />
        </div>
      </Modal> */}
    </div>
  );
};
NewProModal.defaultProps = {
  closeModal: () => { },
  isLandingPage: false,
  redirect: null,
  analytics: null,
};

NewProModal.propTypes = {
  closeModal: PropTypes.func,
  isLandingPage: PropTypes.bool,
  redirect: PropTypes.string,
  analytics: PropTypes.string,
};

export default NewProModal;
