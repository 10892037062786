/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  CardElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRouter } from 'next/router';
import Button from '../../components/UI/Button/Button';
import styles from './StripeForm.module.scss';
import { createStripeCustomer, createStripeSubscription, loadAccount } from '../../../common/data/actions';
import { COLOUR_BLACK_5, COLOUR_RED } from '../../shared/colours';

const StripeForm = (props) => {
  const {
    onClose, propEmail, setLoading,
  } = props;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const lightMode = useSelector((state) => state.main.lightMode);
  const router = useRouter();
  const user = useSelector((state) => state.account.user);
  const defaultEmail = token ? user.email : propEmail;
  const [email, setEmail] = useState(defaultEmail);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  let checkForUpgradeInterval;

  const useOptions = () => {
    const options = {
      style: {
        base: {
          iconColor: lightMode ? '#4F4F4F' : COLOUR_BLACK_5,
          fontSize: 14,
          letterSpacing: '0.025em',
          paddingBottom: '5px',
          color: lightMode ? '#4F4F4F' : COLOUR_BLACK_5,
          fontFamily: 'Poppins, sans-serif',
          '::placeholder': {
            color: lightMode ? '#4F4F4F' : COLOUR_BLACK_5,
          },
        },
        invalid: {
          iconColor: COLOUR_RED,
          color: COLOUR_RED,
        },
      },
    };
    return options;
  };

  const options = useOptions();

  const displayError = (error) => {
    console.log(setEmail);
    setLoading(false);
    toast.error(`${error}`);
  };

  async function fetchAccount() {
    const resp = await dispatch(loadAccount(user.id));
    if (resp.data.data) {
      const u = resp.data.data[0];
      if (u.isPro) {
        clearInterval(checkForUpgradeInterval);
        setLoading(false);
        onClose(email);
      }
    }
  }

  function onSubscriptionComplete(result) {
    if (result.subscription.status === 'active') {
      const amount = result.subscription.plan.amount / 100;
      window.gtag('event', 'entre_pro', {
        currency: 'USD',
        value: amount,
        items: [
          {
            id: 'STRIPE_ANNUAL_PLAN',
            name: 'STRIPE_ANNUAL_PLAN',
            quantity: 1,
            price: amount,
          },
        ],
      });
      // toast.success('Payment has been successfully processed');
      if (router.route === '/billing') {
        window.location.reload();
      }
      if (token) {
        checkForUpgradeInterval = setInterval(fetchAccount, 1000);
      } else {
        setLoading(false);
        onClose(email);
      }
    }
  }

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    console.log('handlePaymentThatRequiresCustomerAction');
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    // eslint-disable-next-line max-len
    console.log(subscription);
    const paymentIntent = invoice
      ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action'
      || (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer.
            // There's a risk of the customer closing the window before the callback.
            // We recommend setting up webhook endpoints later in this guide.
            return {
              priceId,
              subscription,
              invoice,
              paymentMethodId,
            };
          }
          return null;
        })
        .catch((error) => {
          displayError(error.message);
        });
    }
    // No customer action needed.
    return { subscription, priceId, paymentMethodId };
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    console.log('handleRequiresPaymentMethod');
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } if (
      subscription.latest_invoice.payment_intent.status === 'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status,
      );
      displayError('Your card was declined.');
      return null;
    }
    return { subscription, priceId, paymentMethodId };
  }

  async function createSubscription({ customerId, paymentMethodId, priceId }) {
    return (
      createStripeSubscription(customerId, paymentMethodId, priceId)
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.data.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result.data;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => ({
          paymentMethodId,
          priceId,
          subscription: result,
        }))
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          displayError(error.message);
        })
    );
  }

  function retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) {
    return (
      fetch('/retry-invoice', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          paymentMethodId,
          invoiceId,
        }),
      })
        .then((response) => response.json())
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.data.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result.data;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => ({
          // Use the Stripe 'object' property on the
          // returned result to understand what object is returned.
          invoice: result,
          paymentMethodId,
          priceId,
          isRetry: true,
        }))
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          displayError(error.message);
        })
    );
  }

  const processPayment = async () => {
    setProcessing(true);
    const metadata = {};
    if (token) {
      metadata.userId = user.id;
    }
    const r = await createStripeCustomer(email, metadata);
    if (r && r.data && r.data.customer) {
      const customerId = r.data.customer.id;
      const priceId = process.env.STRIPE_ANNUAL_PLAN;
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      // If a previous payment was attempted, get the latest invoice
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        'latestInvoicePaymentIntentStatus',
      );
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        displayError(error.message);
        setLoading(false);
        setProcessing(false);
      } else {
        console.log('[PaymentMethod]', paymentMethod);
        setLoading(true);
        const paymentMethodId = paymentMethod.id;
        if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
          // Update the payment method and retry invoice payment
          const invoiceId = localStorage.getItem('latestInvoiceId');
          retryInvoiceWithNewPaymentMethod({
            customerId,
            paymentMethodId,
            invoiceId,
            priceId,
          });
        } else {
          // Create the subscription
          createSubscription({ customerId, paymentMethodId, priceId });
        }
      }
    }
  };

  const renderButtonLabel = () => {
    if (processing) {
      return '...Upgrading';
    }
    return 'Upgrade to PRO';
  };

  return (
    <>
      <div className={styles.stripeForm}>
        <div className={styles.form}>
          <form>
            <div className={styles.stripeInput}>
              <h6 className={styles.stripeInput__label}>Credit Card Number</h6>
              <CardElement
                className={styles.EntreInput}
                options={options}
                onReady={() => {
                  console.log('CardElement [ready]');
                }}
                onChange={(event) => {
                  console.log('CardElement [change]', event);
                }}
                onBlur={() => {
                  console.log('CardElement [blur]');
                }}
                onFocus={() => {
                  console.log('CardElement [focus]');
                  console.log(propEmail);
                }}
              />
            </div>
          </form>
        </div>
        <footer className={styles.stripeForm__footer}>
          {/* <Button variant="muted" action={onClose}>Back</Button> */}
          <Button
            fullWidth
            isDisabled={processing}
            size="large"
            action={processPayment}
          >
            {renderButtonLabel()}
          </Button>
        </footer>
      </div>
    </>
  );
};

StripeForm.propTypes = {
  propEmail: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setLoading: PropTypes.bool,
};

StripeForm.defaultProps = {
  setLoading: () => {},
};

export default StripeForm;
